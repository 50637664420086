<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('Add New') : $t('Update') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t('Upload') }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t('Reset') }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                      {{ $t('Max size of') }} 700KB</b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col
              v-for="(item, index) in language"
              :key="`${index}_name`"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="$t(item.name)"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name"
                  :vid="item.name"
                >
                  <b-form-input
                    v-if="name[index]"
                    :id="item.name"
                    :name="item.name"
                    :value="name[index]['value']"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t(item.name)"
                    @input="inputLanguage($event, item.lang, index, 'name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-card-text>
                {{ $t('Description') }}
              </b-card-text>
            </b-col>
            <b-col
              v-for="(item, index) in language"
              :key="`${index}_description`"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="$t(item.name)"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name"
                  :vid="item.name"
                >
                  <b-form-textarea
                    v-if="description[index]"
                    :id="item.name"
                    :name="item.name"
                    :value="description[index]['value']"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t(item.name)"
                    @input="
                      inputLanguage($event, item.lang, index, 'description')
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Price')"
                label-for="price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  vid="price"
                  rules="required"
                >
                  <cleave
                    id="price"
                    v-model="price"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Price')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Duration')+' ('+$t('Hour')+')'"
                label-for="durationHour"
              >
                <validation-provider
                  #default="{ errors }"
                  name="durationHour"
                  vid="durationHour"
                  rules="required"
                >
                  <cleave
                    id="durationHour"
                    v-model="durationHour"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Duration')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Duration')+' ('+$t('Minute')+')'"
                label-for="durationMinute"
              >
                <validation-provider
                  #default="{ errors }"
                  name="durationMinute"
                  vid="durationMinute"
                  rules="required"
                >
                  <cleave
                    id="durationMinute"
                    v-model="durationMinute"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Duration')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <small>{{ $t("Change to") }} :
                {{
                  convertMinutesToReadableTime(
                    parseInt(duration.toString().replaceAll(",", ""))
                  )
                }}</small>
            </b-col>
            <!-- <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Type')"
                label-for="register-tagMenu"
              >
                <v-select
                  v-model="type"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionType"
                  :reduce="OptionType => OptionType.value"
                  :clearable="false"
                  class="select-size"
                />
              </b-form-group>
            </b-col> -->
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Category')"
                label-for="register-category"
              >
                <v-select
                  v-model="categoryId"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="respDataCategory"
                  :reduce="respDataCategory => respDataCategory._id"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Text Button')"
                label-for="register-nameURL"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nameURL"
                  vid="nameURL"
                  rules=""
                >
                  <b-form-input
                    id="register-nameURL"
                    v-model="nameURL"
                    name="register-nameURL"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Text Button')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Color Button')"
                label-for="register-colorURL"
              >
                <validation-provider
                  #default="{ errors }"
                  name="colorURL"
                  vid="colorURL"
                  rules=""
                >
                  <b-form-input
                    id="register-colorURL"
                    v-model="colorURL"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Color Button')"
                    type="color"
                  />
                  <b-form-input
                    id="register-colorURL"
                    v-model="colorURL"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Color Button')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Action')"
                label-for="register-tagMenu"
              >
                <v-select
                  v-model="action"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="ActionOption"
                  :reduce="ActionOption => ActionOption.value"
                  :clearable="false"
                  class="select-size"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="action === 'url'"
              cols="12"
            >
              <b-form-group
                :label="$t('URL')"
                label-for="register-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="URL"
                  vid="url"
                  rules=""
                >
                  <b-form-input
                    id="register-url"
                    v-model="url"
                    name="register-url"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('URL')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col
              v-if="action === 'product'"
              cols="12"
            >
              <b-form-group
                :label="$t('Product')"
                label-for="register-product"
              >
                <v-select
                  v-model="productId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="respDataProduct"
                  :reduce="(respDataProduct) => respDataProduct._id"
                  :clearable="false"
                  label="name"
                  @search="onSearchProduct"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormTextarea,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameCategory: {
      type: String,
      required: true,
    },
    storeModuleNameProduct: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      image: '',
      name: [],
      description: [],
      price: 0,
      // duration: 0,
      durationHour: 0,
      durationMinute: 0,
      type: 'course',
      categoryId: null,
      nameURL: '',
      colorURL: '#FFFFFF',
      action: 'none',
      url: '',
      productId: null,
      currentPageProduct: 0,
      searchQueryProduct: '',
      perPageProduct: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    OptionType() {
      return [
        { name: this.$t('Course'), value: 'course' },
        { name: this.$t('Package'), value: 'package' },
      ]
    },
    ActionOption() {
      return [
        { name: this.$t('None'), value: 'none' },
        { name: this.$t('URL'), value: 'url' },
        { name: this.$t('Product'), value: 'product' },
      ]
    },
    respDataCategory() {
      return store.state[this.storeModuleNameCategory].respData != null
        ? store.state[this.storeModuleNameCategory].respData.data.filter(e => e.type.toString() === this.type.toString()).map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    respDataProduct() {
      return store.state[this.storeModuleNameProduct].respData != null
        ? store.state[this.storeModuleNameProduct].respData.data
        : []
    },
    duration() {
      return (
        // eslint-disable-next-line radix
        parseInt(this.durationHour.toString().replaceAll(',', '')) * 60
        // eslint-disable-next-line radix
        + parseInt(this.durationMinute.toString().replaceAll(',', ''))
      )
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )

              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
              console.log(index)
            }
          }
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang,
              )

              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
              console.log(index)
            }
          }
        } else {
          const {
            _id, image, name, description, price, duration, type, categoryId, nameURL, colorURL, action, url, productId,
          } = this.data
          this.dataId = _id
          this.image = image
          this.name = name
          this.description = description
          this.price = price
          // this.duration = duration
          this.durationHour = Math.floor(duration / 60)
          this.durationMinute = duration % 60
          this.type = type
          this.categoryId = categoryId
          this.nameURL = nameURL
          this.colorURL = colorURL
          this.action = action
          this.url = url
          this.productId = productId

          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.name[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }

          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.description.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.description[index].lang,
              )
              if (indexLanguage < 0) {
                this.description.splice(index, 1)
              }
            }
          }
        }
      }
    },
    action(val) {
      if (val) {
        if (val === 'product') {
          this.getProduct()
        }
      }
    },
  },
  methods: {
    convertMinutesToReadableTime(minutes) {
      const MINUTES_IN_YEAR = 525600 // 60 * 24 * 365
      const MINUTES_IN_MONTH = 43800 // 60 * 24 * 30
      const MINUTES_IN_DAY = 1440 // 60 * 24
      const MINUTES_IN_HOUR = 60

      const years = Math.floor(minutes / MINUTES_IN_YEAR)
      minutes %= MINUTES_IN_YEAR

      const months = Math.floor(minutes / MINUTES_IN_MONTH)
      minutes %= MINUTES_IN_MONTH

      const days = Math.floor(minutes / MINUTES_IN_DAY)
      minutes %= MINUTES_IN_DAY

      const hours = Math.floor(minutes / MINUTES_IN_HOUR)
      minutes %= MINUTES_IN_HOUR

      // สร้างข้อความโดยละเว้นค่า 0
      const parts = []
      if (years) parts.push(`${years} ${this.$t('Year')}`)
      if (months) parts.push(`${months} ${this.$t('Month')}`)
      if (days) parts.push(`${days} ${this.$t('Day')}`)
      if (hours) parts.push(`${hours} ${this.$t('Hour')}`)
      if (minutes) parts.push(`${minutes} ${this.$t('Minute')}`)

      // รวมข้อความ
      return parts.join(' ')
    },
    nextPageProduct(page) {
      this.currentPageProduct = page
      this.getProduct()
    },
    onSearchProduct(query) {
      this.searchQueryProduct = query
      this.currentPageProduct = 0
      this.getProduct()
    },
    getProduct() {
      const obj = {
        currentPage: this.currentPageProduct,
        pageSize: this.perPageProduct,
        searchQuery: this.searchQueryProduct,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameProduct}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      } else if (type === 'description') {
        if (this.description[index].lang === lang) {
          this.description[index].value = input
        }
      }
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 700000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
        //         'or',
        //       )} PNG. ${this.$t('Max size of')} 700KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.description = []
      this.price = 0
      // this.duration = 0
      this.durationHour = 0
      this.durationMinute = 0
      this.type = 'course'
      this.categoryId = null
      this.nameURL = ''
      this.colorURL = '#FFFFFF'
      this.action = 'none'
      this.url = ''
      this.productId = null
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            name: this.name,
            description: this.description,
            price: this.price.toString().replaceAll(',', ''),
            duration: this.duration.toString().replaceAll(',', ''),
            type: this.type,
            categoryId: this.categoryId,
            nameURL: this.nameURL,
            colorURL: this.colorURL,
            action: this.action,
            url: this.url,
            productId: this.productId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
